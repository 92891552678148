import React, { useState, useMemo, useEffect, useRef } from 'react';
import { useQuery } from '@apollo/client';
import { DateTime } from 'luxon';
import { useJsApiLoader } from '@react-google-maps/api';
import { Paragraph, Heading, Flex, Box, Button, Text } from 'theme-ui';
import { sort } from 'fast-sort';

import client from 'lib/client';
import { getGeocode, formatResult } from 'lib/geocoding';
import scrollToElement from 'lib/scrollToElement';
import { isMobile } from 'lib/util';

import Autocomplete from 'components/Map/Autocomplete';
import { CentreTile } from 'components/Map/Centre';
import Map from 'components/Map';
import Loader from 'components/common/Loader';
import { Select } from 'components/common/Fields';

import { ReactComponent as LocationIcon } from 'icons/location.svg';

import { LIBRARIES } from 'constants/google';

import { CentreSearchQuery } from 'queries/centres';
import useEmergencyAppointmentMode from 'hooks/useEmergencyAppointmentMode';
import { Formik } from 'formik';

const now = DateTime.local();
const oneWeek = now.plus({ weeks: 1 });

const MAX_RESULTS = 10;

const MapSearch = ({
  setCentre,
  searchResults,
  setSearchResults,
  searchRadius,
  setQueryParams,
  isValidSpecialist,
  specialist,
  specialistSearchMode,
  specialistServices,
  onChangeSpecialist,
  specialistFallbackFormUrl,
}) => {
  const topContainerRef = useRef(null);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: LIBRARIES,
  });
  const [results, setResults] = useState(searchResults?.results || null);
  const [searchQuery, setSearchQuery] = useState(searchResults?.term || '');
  const emergencyAppointmentMode = useEmergencyAppointmentMode();
  const [mapHeight, setMapHeight] = useState(0);

  const canSearch = useMemo(() => {
    return !(
      searchRadius === undefined ||
      (specialistSearchMode && !isValidSpecialist)
    );
  }, [searchRadius, specialistSearchMode, isValidSpecialist]);

  const { data, loading } = useQuery(CentreSearchQuery, {
    client: client,
    skip: !canSearch,
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        coordinates: results
          ? {
              latitude: results?.latitude,
              longitude: results?.longitude,
            }
          : null,
        state: results?.state,
        radius: searchRadius,
        specialist,
      },
      nextAppointment: {
        start: now.toISODate(),
        end: oneWeek.toISODate(),
      },
    },
  });
  useEffect(() => {
    setSearchResults({ term: searchQuery, results });
  }, [results, setSearchResults, searchQuery]);

  useEffect(() => {
    if (isMobile && topContainerRef.current) {
      const height = topContainerRef.current.offsetHeight;
      const maxMapHeight = window.innerHeight - height - 24;
      setMapHeight(maxMapHeight);
    }
  }, [topContainerRef.current]);

  const centres = useMemo(() => {
    const sorted = emergencyAppointmentMode
      ? sort(data?.centreSearch?.centres || []).by([
          {
            asc: centre => {
              return centre.nextAppointment
                ? new Date(centre.nextAppointment.date).valueOf()
                : Number.MAX_SAFE_INTEGER;
            },
          },
          { asc: centre => centre.distance },
          { desc: centre => centre.nextAppointment?.number },
        ])
      : data?.centreSearch?.centres;

    return sorted?.map(centre => ({
      ...centre,
      availableAppointmentsToday:
        !!centre.nextAppointment &&
        DateTime.fromISO(centre.nextAppointment?.date).hasSame(
          DateTime.now(),
          'day'
        ),
      availableAppointmentsTomorrow:
        !!centre.nextAppointment &&
        DateTime.fromISO(centre.nextAppointment?.date).hasSame(
          DateTime.now().plus({
            days: 1,
          }),
          'day'
        ),
    }));
  }, [data, emergencyAppointmentMode]);

  const resultListCentres = useMemo(() => {
    const resultList = emergencyAppointmentMode
      ? centres?.filter(centre => {
          return !!centre?.hours?.find(hour => {
            return (
              hour.isOpen &&
              {
                monday: 1,
                tuesday: 2,
                wednesday: 3,
                thursday: 4,
                friday: 5,
                saturday: 6,
                sunday: 0,
              }[hour.day] === new Date().getDay()
            );
          });
        })
      : centres;

    return (resultList?.length ? resultList : centres)?.slice(0, MAX_RESULTS);
  }, [centres, emergencyAppointmentMode]);

  const onPositionChange = async ({ coords }) => {
    const results = await getGeocode({
      location: { lat: coords.latitude, lng: coords.longitude },
    });
    const result = results.find(r => r.types.includes('locality'));
    const details = await formatResult(result);
    setResults(details);
  };

  const onPositionError = error => {
    console.error(error);
  };

  const getGeoPosition = () => {
    navigator.geolocation.getCurrentPosition(
      onPositionChange,
      onPositionError,
      {
        enableHighAccuracy: true,
        timeout: 10 * 1000,
        maximumAge: 60000,
      }
    );
  };

  const onSetSearchQuery = query => {
    if (query) {
      setSearchQuery(query);
      setQueryParams({ address: query }, { replace: true });
    }
  };

  if (!isLoaded) {
    return <Loader />;
  }

  return (
    <Flex
      sx={{
        backgroundColor: 'background',
        flexDirection: 'column',
        '@media (min-width: 576px)': {
          position: 'absolute',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          height: '100%',
        },
      }}
    >
      <Box
        id="top"
        ref={topContainerRef}
        sx={{
          padding: '16px',
          '@media (min-width: 576px)': {
            paddingX: '28px',
            paddingY: '32px',
            width: '410px',
            height: '100%',
            overflowY: 'auto',
          },
        }}
      >
        <Flex
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Heading as="h2" variant="styles.h3" color="primary">
            {emergencyAppointmentMode ? 'Find an appointment' : 'Find a centre'}
          </Heading>
          <Flex
            onClick={() => getGeoPosition()}
            sx={{
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <Paragraph
              sx={{
                cursor: 'pointer',
                textDecoration: 'underline',
                fontSize: 0,
                textAlign: 'right',
                marginRight: '8px',
              }}
            >
              use my location
            </Paragraph>
            <LocationIcon width="16px" />
          </Flex>
        </Flex>

        <Autocomplete
          setResults={setResults}
          defaultValue={searchResults?.term}
          onSelect={onSetSearchQuery}
        />

        {specialistSearchMode && (
          <Formik
            initialValues={{ specialist }}
            onSubmit={values => onChangeSpecialist(values.specialist)}
          >
            <Select
              name="specialist"
              value={isValidSpecialist ? specialist : ''}
              choices={[
                { value: '', label: 'Select a specialist service' },
              ].concat(specialistServices)}
              submitFormOnChange
            />
          </Formik>
        )}

        {canSearch &&
          !loading &&
          !!centres?.length &&
          !data?.centreSearch?.showResultsList && (
            <Paragraph
              sx={{
                marginTop: '28px',
              }}
            >
              Please enter your suburb or postcode{' '}
              {specialistSearchMode && 'and specialist service'} to find your
              nearest dental centre.
            </Paragraph>
          )}

        {canSearch &&
          !loading &&
          !!centres?.length &&
          data?.centreSearch?.showResultsList && (
            <Paragraph
              sx={{
                fontSize: 1,
                marginTop: '28px',
              }}
              onClick={() => scrollToElement(document?.getElementById('map'))}
            >
              {emergencyAppointmentMode ? (
                <>
                  If no appointments are displayed but a centre near you is open
                  please call as additional emergency appointments are reserved
                  daily.
                </>
              ) : centres?.[0]?.distance > searchRadius / 1000 ? (
                <>
                  We couldn't find any centres near you. You can try searching a
                  different area or explore centres using{' '}
                  <Text
                    sx={{
                      '@media (max-width: 576px)': {
                        cursor: 'pointer',
                        color: '#255EC1',
                        textDecoration: 'underline',
                      },
                    }}
                    onClick={() =>
                      scrollToElement(document?.getElementById('map'))
                    }
                  >
                    the map
                  </Text>
                  .
                </>
              ) : (
                <>
                  Showing{' '}
                  {centres?.length <= MAX_RESULTS
                    ? centres?.length
                    : MAX_RESULTS}{' '}
                  nearest locations to{' '}
                  {results?.suburb || results?.address || results?.state}.{' '}
                  <Text
                    sx={{
                      '@media (max-width: 576px)': {
                        cursor: 'pointer',
                        color: '#255EC1',
                        textDecoration: 'underline',
                      },
                    }}
                    onClick={() =>
                      scrollToElement(document?.getElementById('map'))
                    }
                  >
                    See map
                  </Text>{' '}
                  for more locations.
                </>
              )}
            </Paragraph>
          )}

        {canSearch &&
          !loading &&
          !centres?.length &&
          data?.centreSearch?.showResultsList &&
          (specialistFallbackFormUrl ? (
            <>
              <Paragraph
                sx={{
                  fontSize: 1,
                  marginTop: '28px',
                }}
              >
                Please provide your call back details and we'll find a centre in
                this area with availabilities.
              </Paragraph>
              <Button
                as="a"
                target="_top"
                href={specialistFallbackFormUrl}
                variant="primary"
                sx={{
                  marginTop: '28px',
                }}
              >
                Book a consultation
              </Button>
            </>
          ) : (
            <Paragraph
              sx={{
                fontSize: 1,
                marginTop: '28px',
              }}
            >
              No centres located near you.
            </Paragraph>
          ))}

        {canSearch && loading && results && (
          <Paragraph
            sx={{
              fontSize: 1,
              marginTop: '28px',
            }}
          >
            Searching for centres near{' '}
            <strong>
              {results?.suburb || results?.address || results?.state}
            </strong>
            ...
          </Paragraph>
        )}

        {canSearch && loading && !results && (
          <Paragraph
            sx={{
              fontSize: 1,
              marginTop: '28px',
            }}
          >
            Loading centres...
          </Paragraph>
        )}

        {canSearch &&
          !loading &&
          data?.centreSearch?.showResultsList &&
          resultListCentres?.map(centre => (
            <CentreTile
              emergencyAppointmentMode={emergencyAppointmentMode}
              key={centre.id}
              centre={centre}
              setCentre={setCentre}
              specialistSearchMode={specialistSearchMode}
              specialistServices={specialistServices}
            />
          ))}
      </Box>
      <Box
        sx={{
          width: '100%',
          height: mapHeight ? `${mapHeight}px` : '300px',
          paddingX: '16px',
          paddingBottom: '16px',
          display:
            canSearch && !loading && data?.centreSearch?.centres.length
              ? 'block'
              : 'none',
          '@media (min-width: 576px)': {
            display: 'block',
            padding: 0,
            width: 'calc(100vw - 410px)',
            height: '100%',
          },
        }}
      >
        <Map
          results={results}
          data={searchResults ? data : null}
          searchRadius={searchRadius}
          loading={loading}
          setCentre={setCentre}
        />
      </Box>
    </Flex>
  );
};

export default MapSearch;
