import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { Flex, Box, Button, Paragraph } from 'theme-ui';
import { Formik } from 'formik';
import { useMutation } from '@apollo/client';
import scrollToElement from 'lib/scrollToElement';
import { trackEvent } from 'lib/GA';
import Loader from 'components/common/Loader';
import { RequestAppointmentMutation } from '../../queries/contact.js';
import FormWrapper from 'components/common/FormWrapper';
import Alert from 'components/common/Alert';
import SuccessIcon from 'components/common/Icons/SuccessIcon';
import { FieldWrapper, FieldHeading } from 'components/common/FieldCard';
import {
  Checkbox,
  Input,
  Select,
  Recaptcha,
  Textarea,
} from 'components/common/Fields';
import useEmergencyAppointmentMode from 'hooks/useEmergencyAppointmentMode';

const FallbackForm = () => {
  const [submitted, setSubmitted] = useState(false);
  const isEmergencyAppointmentMode = useEmergencyAppointmentMode();

  const initialForm = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    appointmentType: '',
    agreed: false,
    comments: '',
    recaptcha: '',
  };
  const [submitError, setError] = useState();
  const [recaptchaWidget, setRecaptchaWidget] = useState();

  useEffect(() => {
    if (submitted) {
      scrollToElement(undefined, -100);
    }
  }, [submitted]);

  const [requestAppointment] = useMutation(RequestAppointmentMutation);

  const submitForm = async (form, { resetForm, setFieldValue, setTouched }) => {
    setError();

    const { recaptcha, ...input } = form;

    try {
      trackEvent({
        event: 'bookingRequest',
        appointmentType: input?.appointmentType,
        dateOfBooking: moment().format('DD-MM-YYYY'),
      });
    } catch (e) {
      console.error(e);
    }

    try {
      // currently only executing and not using value
      grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY_V3, {action: 'submit'})
        .then(function(token) {
          console.log('Recaptcha token:', token);
        });
    } catch (e) {
      console.error(e);
    }

    try {
      await requestAppointment({
        variables: {
          validation: {
            token: recaptcha,
          },
          input,
        },
      });
      resetForm({});
      setSubmitted(true);
    } catch (e) {
      console.error(e);
      setError(e);
    } finally {
      // Reset ReCAPTCHA component to allow for retry if submit function fails
      setFieldValue('recaptcha', '');
      window.grecaptcha?.reset(recaptchaWidget);
      setTouched({}, false);
    }

    return true;
  };

  return submitted ? (
    <SuccessMessage setSubmitted={setSubmitted} />
  ) : (
    <Formik
      initialValues={initialForm}
      validate={validate}
      onSubmit={submitForm}
    >
      {({ values, handleSubmit, isSubmitting }) => {
        return (
          <form onSubmit={handleSubmit}>
            <FormWrapper
              title="Request an appointment"
              alert={
                !isEmergencyAppointmentMode && (
                  <Alert variant="info">
                    In case of emergency call direct to make an appointment. All
                    fields are required unless otherwise indicated.
                  </Alert>
                )
              }
            >
              <FieldWrapper>
                <FieldHeading>Your details</FieldHeading>
                <Flex
                  sx={{
                    flexDirection: 'column',
                    flexWrap: 'nowrap',
                    gridGap: '16px',
                  }}
                >
                  <Input name="firstName" label="First name" required />

                  <Input name="lastName" label="Last name" required />

                  <Input name="phoneNumber" label="Contact number" required />

                  <Input
                    name="email"
                    type="email"
                    label="Email address"
                    required
                  />

                  <Select
                    name="appointmentType"
                    label="Appointment Type"
                    required
                    choices={[
                      { value: '', label: 'Please select an option' },
                      {
                        value: 'Check up & Clean, New Patient',
                        label: 'Check up & Clean, New Patient',
                      },
                      {
                        value: 'Check up & Clean, Adult',
                        label: 'Check up & Clean, Adult',
                      },
                      {
                        value: 'Check up & Clean, Child',
                        label: 'Check up & Clean, Child',
                      },
                      {
                        value: 'General Examination, New Patient',
                        label: 'General Examination, New Patient',
                      },
                      {
                        value: 'General Examination, Adult',
                        label: 'General Examination, Adult',
                      },
                      {
                        value: 'General Examination, Child',
                        label: 'General Examination, Child',
                      },
                      { value: 'Toothache/Pain', label: 'Toothache/Pain' },
                      {
                        value: 'Crown Consultation',
                        label: 'Crown Consultation',
                      },
                      {
                        value: 'Implant Consultation',
                        label: 'Implant Consultation',
                      },
                      {
                        value: 'Medicare Child Dental Benefit Schedule',
                        label: 'Medicare Child Dental Benefit Schedule',
                      },
                    ]}
                  />

                  <Textarea name="comments" label="Comments" rows="4" />
                  <Checkbox
                    name="agreed"
                    label={
                      <span>
                        I have read and agree to the{' '}
                        <a
                          href="https://www.pacificsmilesdental.com.au/privacy-policy/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Privacy Policy
                        </a>
                      </span>
                    }
                    labelProps={{
                      mb: 0,
                    }}
                  />
                </Flex>
              </FieldWrapper>
              <Paragraph variant="disclaimer">
                This site is protected by reCAPTCHA and the Google&nbsp;
                <a href="https://policies.google.com/privacy">Privacy Policy</a> and&nbsp;
                <a href="https://policies.google.com/terms">Terms of Service</a> apply.
              </Paragraph>

              <Box sx={{ marginTop: '32px' }}>
                <Recaptcha setRecaptchaWidget={setRecaptchaWidget} />
              </Box>

              <Flex
                sx={{
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: ['24px', '24px', '56px'],
                }}
              >
                {isSubmitting ? (
                  <Loader />
                ) : (
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={!formValid(values)}
                  >
                    Submit
                  </Button>
                )}
                {submitError && (
                  <Paragraph variant="error">{submitError?.message}</Paragraph>
                )}
              </Flex>
            </FormWrapper>
          </form>
        );
      }}
    </Formik>
  );
};

const SuccessMessage = ({ setSubmitted }) => {
  return (
    <FormWrapper>
      <FieldWrapper alignItems="center" textAlign="center">
        <FieldHeading>Thanks for your request</FieldHeading>
        <Paragraph sx={{ textAlign: 'center' }}>
          We will contact you within&nbsp;&nbsp;1&nbsp;&nbsp;business day to
          arrange a suitable time for your appointment.
        </Paragraph>
        <SuccessIcon
          sx={{
            minWidth: '96px',
            marginTop: '32px',
          }}
        />
        <Button sx={{ marginTop: '32px' }} onClick={() => setSubmitted(false)}>
          Request another appointment
        </Button>
      </FieldWrapper>
    </FormWrapper>
  );
};

const validate = values => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = 'First name is required.';
  }
  if (!values.lastName) {
    errors.lastName = 'Last name is required.';
  }
  if (!values.email) {
    errors.email = 'Email address is required.';
  }
  if (!values.phoneNumber) {
    errors.phoneNumber = 'Phone number is required.';
  }
  if (!values.appointmentType) {
    errors.appointmentType = 'Appointment type is required.';
  }
  if (!values.agreed) {
    errors.agreed = 'This field is required.';
  }
  if (!values.recaptcha) {
    errors.recaptcha = 'Recaptcha is required.';
  }
  return errors;
};

const formValid = values => {
  return (
    values.firstName &&
    values.lastName &&
    values.email &&
    values.phoneNumber &&
    values.appointmentType &&
    values.agreed &&
    values.recaptcha
  );
};

export default FallbackForm;
